import { mapGetter, mapAction } from 'CommonUtils/store/state.js';
import {
  //FACET_DELIVERY,
  //CURRENT_STEP,
  DELIVERY_PARAMS,
  //DELIVERY_TYPE,
} from 'CommonUtils/schemas/facetDelivery';
import useUrl from 'CommonComposables/useUrl.js';
import useFacetFilterLogic from 'CommonComposables/useFacetFilterLogic.js';
import UtilsString from 'CommonUtils/strings.js';

const useDelivery = ({ emit }) => {
  const moduleName = mapGetter('filter/getActiveFilterModuleName').value;

  let deliveryFacet;
  if (moduleName === 'filter') {
    deliveryFacet = mapGetter('page/getDeliveryFacet');
  } else {
    deliveryFacet = mapGetter(`${moduleName}/getDeliveryFacet`);
  }
  const isStoreMode = mapGetter('page/isStoreMode');

  const filters = mapGetter(`${moduleName}/getFilters`);
  const currentFilterUrl = mapGetter(`${moduleName}/getCurrentFilterUrl`);
  const currentlySelectedDeliveryTypes = mapGetter(`${moduleName}/getCurrentlySelectedDeliveryTypes`);

  const addCurrentUrl = mapAction(moduleName, 'addCurrentUrl');
  const removeItemChecked = mapAction(moduleName, 'removeFilter');

  const eciExpress = mapGetter('cart/getEciExpress');
  const clickAndCar = mapGetter('cart/getClicAndCar');
  const isLoadingCart = mapGetter('cart/getLoadingStatus');

  const { updateUrlParams, getCurrentParam, hasParam } = useUrl();
  const { getSplitFilter, existCurrentFilter } = useFacetFilterLogic();
  const deliveryFiltersCount = ref(0);
  const facetDeliveryFilter = ref('');

  const hasDelivery = computed(() => {
    return deliveryFacet.value?.delivery?.default.length || deliveryFacet.value?.delivery?.additional.length;
  });
  const hasCollect = computed(() => {
    return deliveryFacet.value?.collect?.default.length || deliveryFacet.value?.collect?.additional.length;
  });
  const mustShowFacetDelivery = computed(() => {
    return !isStoreMode.value && (hasDelivery.value || hasCollect.value);
  });
  const hasCurrentFilter = computed(() => {
    return currentFilterUrl.value ? currentFilterUrl.value.split(',,').length : 0;
  });

  const flattenedDeliveryFilters = computed(() => {
    const deliveryDefault = deliveryFacet.value?.delivery?.default ?? [];
    const deliveryAdditional = deliveryFacet.value?.delivery?.additional ?? [];
    const collectDefault = deliveryFacet.value?.collect?.default ?? [];
    const collectAdditional = deliveryFacet.value?.collect?.additional ?? [];
    return deliveryDefault.concat(deliveryAdditional).concat(collectDefault).concat(collectAdditional);
  });
  const selectedDeliveryFilterLabels = computed(() => {
    const ids = currentlySelectedDeliveryTypes.value?.filter((item) => item.isChecked).map((item) => item.id);
    const labels = ids?.map((id) => getDeliveryLabelById(id));
    return labels;
  });
  const getDeliveryLabelById = (id) => {
    const label = flattenedDeliveryFilters.value.find((deliveryFilter) => deliveryFilter._fid === id)?.label;
    return UtilsString.capitalizeFirstLetter(label);
  };

  const updateDeliveryFiltersCount = () => {
    const CLICK_AND_CAR_DELIVERY = '-click_and_car';
    const ECI_EXPRESS_DELIVERY = '-eci_express';

    let count = filters.value.filter((box) => {
      return box.id.split('::')[0] === 'delivery_time' && box.id.split('::')[1].length != 4;
    }).length;

    const hasEciExpressDefault = filters.value.some(
      (delivery) => delivery._fid === eciExpress.value.general_centre || delivery.label.includes(ECI_EXPRESS_DELIVERY),
    );
    const hasClicAndCarDefault = filters.value.some(
      (delivery) =>
        delivery._fid === clickAndCar.value.general_centre || delivery.label.includes(CLICK_AND_CAR_DELIVERY),
    );

    if (hasEciExpressDefault && hasParam(DELIVERY_PARAMS.DELIVERY_KEY, DELIVERY_PARAMS.DELIVERY)) count += 1;
    if (hasClicAndCarDefault && hasParam(DELIVERY_PARAMS.DELIVERY_KEY, DELIVERY_PARAMS.COLLECT)) count += 1;
    if (
      hasEciExpressDefault &&
      hasClicAndCarDefault &&
      hasParam(DELIVERY_PARAMS.DELIVERY_KEY, DELIVERY_PARAMS.DELIVERY_AND_COLLECT)
    )
      count += 2;
    deliveryFiltersCount.value = count;
  };

  const updateUrlDeliveryFilters = () => {
    const TYPES_FILTER = getSplitFilter();
    const currentFilter = existCurrentFilter(TYPES_FILTER);

    if (currentFilter) {
      checkIfGeneralCentreAreValid(currentFilter);
    }

    const hasEciExpressDefault = filters.value.some((delivery) => delivery._fid === eciExpress.value.general_centre);
    const hasClicAndCarDefault = filters.value.some((delivery) => delivery._fid === clickAndCar.value.general_centre);

    if (
      (eciExpress.value.general_centre === clickAndCar.value.general_centre &&
        getCurrentParam(DELIVERY_PARAMS.DELIVERY_KEY) === DELIVERY_PARAMS.COLLECT) ||
      getCurrentParam(DELIVERY_PARAMS.DELIVERY_KEY) === DELIVERY_PARAMS.DELIVERY
    ) {
      return updateUrlParams(getCurrentParam(DELIVERY_PARAMS.DELIVERY_KEY), DELIVERY_PARAMS.DELIVERY_KEY);
    }

    if (hasEciExpressDefault && !hasClicAndCarDefault)
      updateUrlParams(DELIVERY_PARAMS.DELIVERY, DELIVERY_PARAMS.DELIVERY_KEY);
    if (!hasEciExpressDefault && hasClicAndCarDefault)
      updateUrlParams(DELIVERY_PARAMS.COLLECT, DELIVERY_PARAMS.DELIVERY_KEY);
    if (hasEciExpressDefault && hasClicAndCarDefault)
      updateUrlParams(DELIVERY_PARAMS.DELIVERY_AND_COLLECT, DELIVERY_PARAMS.DELIVERY_KEY);
  };

  const checkIfGeneralCentreAreValid = (currentFilter) => {
    const splitMarker = '::';
    if (currentFilter && currentFilter.includes(splitMarker)) {
      const splitFilters = currentFilter.split(splitMarker)[1].split(',,');
      splitFilters.forEach((filter) => {
        if (
          filter.length === 4 &&
          filter !== eciExpress.value.general_centre &&
          filter !== clickAndCar.value.general_centre
        ) {
          removeFilter({ _fid: filter });
        }
      });
    }
  };

  const removeFilter = async (trail) => {
    const FILTER_SEPARATOR = '::';
    const SPLIT_SEPARATOR = ',,';
    const SLASH_SEPARATOR = '/';

    const SPLIT_URL = decodeURI(facetDeliveryFilter.value)
      .replace(trail._fid, '')
      .replace(FILTER_SEPARATOR + SPLIT_SEPARATOR, FILTER_SEPARATOR)
      .replace(SPLIT_SEPARATOR + SPLIT_SEPARATOR, SPLIT_SEPARATOR);

    facetDeliveryFilter.value = SPLIT_URL;

    const NORMALIZE_URL = SPLIT_URL.split(SLASH_SEPARATOR).filter((filter) => {
      if (filter.split(FILTER_SEPARATOR)[1] && filter.split(FILTER_SEPARATOR)[1].length > 0) return filter;
    });

    await removeItemChecked(trail);
    await addCurrentUrl(NORMALIZE_URL.join(SLASH_SEPARATOR));
    emit('handleFilter', {}, true);
  };

  watch(isLoadingCart, () => {
    if (isLoadingCart) {
      updateUrlDeliveryFilters();
      updateDeliveryFiltersCount();
    }
  });
  watch(hasCurrentFilter, (currFilter) => {
    if (!currFilter) {
      deliveryFiltersCount.value = 0;
    }
  });

  onMounted(() => {
    facetDeliveryFilter.value = currentFilterUrl.value;
  });

  return {
    hasDelivery,
    hasCollect,
    mustShowFacetDelivery,
    updateDeliveryFiltersCount,
    deliveryFiltersCount,
    updateUrlDeliveryFilters,
    selectedDeliveryFilterLabels,
  };
};

export default useDelivery;
