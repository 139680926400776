import { useStore } from 'vuex';
import { getCurrentInstance } from 'vue';
import { mapGetter } from 'CommonUtils/store/state.js';

const useFilter = (FILTER_KEY) => {
  const { emit } = getCurrentInstance();
  const store = useStore();
  const FILTER_SEPARATOR = '::';
  const SPLIT_SEPARATOR = ',,';
  const SLASH_SEPARATOR = '/';

  const moduleName = mapGetter('filter/getActiveFilterModuleName').value;

  const pushCurrentFilterInUrl = (TYPES_FILTER, label) => {
    TYPES_FILTER.push(FILTER_KEY + FILTER_SEPARATOR + label);
    return TYPES_FILTER;
  };

  const existCurrentFilter = (TYPES_FILTER) =>
    TYPES_FILTER.find((filter) => filter.split(FILTER_SEPARATOR)[0] === FILTER_KEY);

  const removeCurrentFilter = (TYPES_FILTER, label) => {
    return TYPES_FILTER.map((filter) => {
      const [key, value] = filter.split(FILTER_SEPARATOR);
      if (key !== FILTER_KEY) return filter;
      const splitChild = value.split(SPLIT_SEPARATOR);
      const filtersChild = removeFirstOccurrenceOfElement(splitChild, encodeURI(label));
      if (label) store.dispatch(`${moduleName}/removeFilter`, { _fid: label });
      if (!filtersChild.length) return '';
      return key + FILTER_SEPARATOR + filtersChild.join(SPLIT_SEPARATOR);
    });
  };

  const addCurrentFilter = (TYPES_FILTER, label) => {
    return TYPES_FILTER.map((filter) => {
      const [key, value] = filter.split(FILTER_SEPARATOR);
      if (key !== FILTER_KEY) return filter;
      const SPLIT_CHILD = value.split(SPLIT_SEPARATOR);
      return SPLIT_CHILD.map(() => {
        return filter + SPLIT_SEPARATOR + encodeURI(label);
      })[0];
    });
  };

  const updateCurrentFilterUrl = async (NORMALIZE_URL, needReset = true) => {
    await store.dispatch(`${moduleName}/addCurrentUrl`, NORMALIZE_URL.join(SLASH_SEPARATOR));
    if (needReset) emit('handleFilter', {}, true);
  };

  const removeFirstOccurrenceOfElement = (sourceArray, element) => {
    const index = sourceArray.indexOf(element);
    if (index === -1) return sourceArray;
    return [...sourceArray.slice(0, index), ...sourceArray.slice(index + 1)];
  };

  return {
    pushCurrentFilterInUrl,
    existCurrentFilter,
    removeCurrentFilter,
    addCurrentFilter,
    updateCurrentFilterUrl,
  };
};

export default useFilter;
