const axios = require('axios');

let ALGOLIA_QUERY_FAKE;

async function getAlgoliaQueryFake(index, url_params, user_token) {
  if (!index || !url_params || !user_token) return null;

  const dissectUrlparams = new URLSearchParams(url_params);
  dissectUrlparams.has('userToken')
    ? dissectUrlparams.set('userToken', user_token)
    : dissectUrlparams.append('userToken', user_token);

  const body_stringify = { requests: [{ indexName: index, params: dissectUrlparams.toString() }] };

  const response = await axios.post(ALGOLIA_QUERY_FAKE, body_stringify);

  if (response.status === 200) {
    return response.data?.results?.[0]?.queryID;
  }
  return null;
}

function initAlgoliaQueryFakeUrl(url) {
  ALGOLIA_QUERY_FAKE = url;
}

module.exports = {
  initAlgoliaQueryFakeUrl,
  getAlgoliaQueryFake,
};
