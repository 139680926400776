<template>
  <div v-if="loading" class="loader-wrapper">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name:'Loading',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>