<template>
  <div :class="containerClass">
    <slot></slot>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  const NAVIGATION_TYPES = { FORWARD: 'FORWARD', BACKWARD: 'BACKWARD' };
  export default {
    props: {
      initialPage: {
        type: Number,
        default: 1,
      },
      page: {
        type: Number,
        default: 1,
      },
      containerClass: {
        type: String,
        default: 'container__infinite_scroll',
      },
      itemsPerPage: {
        type: Number,
        default: 3,
      },
      ratioObserver: {
        type: Number,
        default: 0.25,
      },
      options: {
        type: Object,
        default: () => ({
          root: null,
          rootMargin: '0px 0px 0px 0px',
          threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
          // delay: 500
        }),
      },
      elements: {
        type: Array,
        required: true,
      },
      isFetching: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['callback'],
    data() {
      return {
        observer: null,
        avoidBackwardFetch: false,
      };
    },
    computed: {
      ...mapGetters('layout', {
        scrolledByUser: 'getUserScroll',
      }),
    },
    mounted() {
      this.handlerObserver();
    },
    unmounted() {
      this.observer.disconnect();
    },
    methods: {
      fetch(direction) {
        if (this.isFetching) return;
        let page = this.page;
        if (direction === NAVIGATION_TYPES.FORWARD) page = page + 1;
        else if (direction === NAVIGATION_TYPES.BACKWARD) {
          if (page === 1) return;
          if (this.avoidBackwardFetch) {
            this.avoidBackwardFetch = false;
            return;
          }
          page = page - 1;
        }
        return this.$emit('callback', {
          currentPage: page,
          itemsPerPage: this.itemsPerPage,
          direction,
        });
      },
      handlerObserver() {
        this.observer = new IntersectionObserver((entries) => {
          return entries.forEach((entry) => {
            const { isIntersecting, intersectionRatio, target } = entry;

            const direction = this.findElement(target);
            const appearForwardCase =
              isIntersecting && intersectionRatio === 1 && direction === NAVIGATION_TYPES.FORWARD;
            const shouldFetch = (isIntersecting || intersectionRatio > this.ratioObserver) && this.scrolledByUser;

            if (!shouldFetch && !appearForwardCase) return;
            this.fetch(direction);
          });
        }, this.options);
        this.elements.forEach((element) => {
          const sentinel = document.querySelector(element.selector);
          if (!sentinel) return;

          if (element.watchChildren && sentinel?.childNodes?.length)
            sentinel.childNodes.forEach((child) => child.nodeType != 8 && this.observer.observe(child));
          this.observer.observe(sentinel);
        });
      },
      findElement(target) {
        const intersected = this.elements.find((element) => {
          const sentinel = document.querySelector(element.selector);
          if (element.watchChildren && sentinel?.childNodes?.length)
            return [...sentinel.childNodes].find((child) => child.nodeType != 8 && target.isEqualNode(child));
          return target.isEqualNode(sentinel);
        });
        if (!intersected || !intersected.direction) return NAVIGATION_TYPES.FORWARD;
        return intersected.direction;
      },
    },
  };
</script>

<style lang="less" scoped>
  .container__infinite_scroll {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
</style>
