<template>
  <div class="wrapper_grid_options">
    <p class="wrapper_grid_options__title">
      Vista
    </p>
    <div
      role="button"
      tabindex="0"
      aria-pressed="false"
      class="wrapper_icon_box"
      @keyup.enter="handleGridChange(GRID_TYPES.BIG_PRODUCTS)"
      @click="handleGridChange(GRID_TYPES.BIG_PRODUCTS)">
      <i
        class="icon_box icon_box_big"
        :class="selectedGridType === GRID_TYPES.BIG_PRODUCTS ? 'active' : ''"></i>
    </div>
    <div
      role="button"
      tabindex="0"
      aria-pressed="false"
      class="wrapper_icon_box"
      @keyup.enter="handleGridChange(GRID_TYPES.LITTLE_PRODUCTS)"
      @click="handleGridChange(GRID_TYPES.LITTLE_PRODUCTS)">
      <i
        class="icon_box icon_box_little"
        :class="selectedGridType === GRID_TYPES.LITTLE_PRODUCTS ? 'active' : ''"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GRID_TYPES from 'CommonUtils/gridTypes';
import { defer } from 'CommonUtils/operations/defer';

export default {
  name: 'SwitchGrid',
  data() {
    return {
      GRID_TYPES,
      futureGridType: null,
    };
  },
  computed: {
    ...mapGetters('plp', {
      gridType: 'getGridType'
    }),
    selectedGridType: function() {
      return this.futureGridType ?? this.gridType;
    }
  },
  methods: {
    ...mapActions('plp', ['changeGrid']),
    handleGridChange: function(gridType) {
      this.futureGridType = gridType;

      // Taking costly tasks out of the main thread
      defer(() => {
        this.changeGrid(gridType);
      }, true).then(() => {
        this.futureGridType = null;
      });
    }
  },
};
</script>
