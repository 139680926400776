import { useStore } from 'vuex';
import { ref, reactive } from 'vue';
import useEciExpress from 'CommonComposables/useEciExpress.js';
import useFilter from 'CommonComposables/useFilter.js';

import { CURRENT_STEP, DELIVERY_TYPE, FACET_DELIVERY } from 'CommonUtils/schemas/facetDelivery';
import { mapGetter, mapAction } from 'CommonUtils/store/state.js';
import { DELIVERY_PARAMS } from 'CommonUtils/schemas/facetDelivery';

import UtilsAnalytics from 'CommonUtils/analytics.js';
import { useI18n } from 'vue-i18n';

const DELIVERY_KEY = 'delivery_time';

const useFacetFilterLogic = () => {
  const store = useStore();
  const { t } = useI18n();

  const currentStep = ref(CURRENT_STEP.FACET_DELIVERY);
  const deliveryType = ref(DELIVERY_TYPE.DELIVERY);

  const apiError = ref(null);

  const expressDelivery = reactive({ postalCode: null, timeSlot: null, generalCentre: null });
  const clickAndCarCollect = reactive({ postalCode: null, timeSlot: null, generalCentre: null, name: null });

  const moduleName = mapGetter('filter/getActiveFilterModuleName').value;

  const currentFilterUrl = mapGetter(`${moduleName}/getCurrentFilterUrl`);
  const currentlySelectedDeliveryTypes = mapGetter(`${moduleName}/getCurrentlySelectedDeliveryTypes`);
  const eciExpress = mapGetter('cart/getEciExpress');
  const clickAndCar = mapGetter('cart/getClicAndCar');
  const setCurrentlySelectedDeliveryTypes = mapAction(moduleName, 'setCurrentlySelectedDeliveryTypes');
  const setSelectedDeliveryType = mapAction(moduleName, 'setSelectedDeliveryType');
  const addToastMessage = mapAction('toastMessageState', 'addMessage');
  
  const {
    isLoading,
    handleEciExpressPostalCodeLS,
    handleEciExpressTimeSlotLS,
    handleClickNCarCenterLS,
    handleClickNCarTimeSlotLS,
    resetState,
  } = useEciExpress();

  const { pushCurrentFilterInUrl, existCurrentFilter, removeCurrentFilter, addCurrentFilter, updateCurrentFilterUrl } =
    useFilter(DELIVERY_KEY);

  const closeModal = async () => {
    setDeliveryType(DELIVERY_TYPE.DELIVERY);
    setCurrentStep(CURRENT_STEP.FACET_DELIVERY);
    store.dispatch('uiState/hideAllModals');
  };

  const setDeliveryType = (type) => (deliveryType.value = type);

  const setCurrentStep = (step) => (currentStep.value = step);

  const setEciExpress = () => {
    const { postal_code, time_slot, general_centre } = eciExpress.value;
    if (postal_code) expressDelivery.postalCode = postal_code;
    if (general_centre) expressDelivery.generalCentre = general_centre;
    if (time_slot) expressDelivery.timeSlot = setTimeSlots(time_slot);
  };

  const setClickAndCar = () => {
    const { postal_code, general_centre, name, time_slot } = clickAndCar.value;
    if (postal_code) clickAndCarCollect.postalCode = postal_code;
    if (general_centre) clickAndCarCollect.generalCentre = general_centre;
    if (name) clickAndCarCollect.centreTitle = name;
    if (time_slot) clickAndCarCollect.timeSlot = setTimeSlots(time_slot);
  };

  const getSplitFilter = () => {
    const currentFilter = currentFilterUrl.value;
    let TYPES_FILTER = [];
    if (currentFilter) TYPES_FILTER = currentFilter.split('/');
    return TYPES_FILTER;
  };

  const setTimeSlots = (time_slot) => {
    const start_date = new Date(time_slot.start_date.replace(/\s/, 'T'));
    const end_date = new Date(time_slot.end_date.replace(/\s/, 'T'));
    const payload = {
      start_date: start_date.getHours() + ':00',
      end_date: end_date.getHours() + ':00',
      today: time_slot.today,
      tomorrow: time_slot.tomorrow,
    };
    return payload;
  };

  const setFilterInUrl = async (label, needRemove = false) => {
    const TYPES_FILTER = getSplitFilter();
    const method = needRemove ? removeCurrentFilter(TYPES_FILTER, label) : addCurrentFilter(TYPES_FILTER, label);
    let NORMALIZE_URL;
    const deliveryTime = existCurrentFilter(TYPES_FILTER);
    if (deliveryTime) NORMALIZE_URL = method;
    else NORMALIZE_URL = pushCurrentFilterInUrl(TYPES_FILTER, label);
    await updateCurrentFilterUrl(NORMALIZE_URL);
  };

  const handleEditTimeSlotsClickNCar = () => {
    setDeliveryType(DELIVERY_TYPE.COLLECT);
    setCurrentStep(CURRENT_STEP.SET_TIME_SLOTS);
  };

  const handlePostalCodeEciExpress = async (postal_code, generalCentre) => {
    setDeliveryType(DELIVERY_TYPE.DELIVERY);
    const { ok, data, error } = await handleEciExpressPostalCodeLS(postal_code);
    if (ok) {
      apiError.value = null;

      if (!data?.success && data.original_error_code === 404) {
        const toastPcMessage = `<div class="toast-icon-wrapper"><i class="toast-error"></i></div><div> ${ t('postal_code.not_found') } </div>`;

        addToastMessage(toastPcMessage);
        return;
      }

      const NORMALIZE_URL = await removeFilter(generalCentre);
      await updateCurrentFilterUrl(NORMALIZE_URL, false);
      setCurrentStep(CURRENT_STEP.FACET_DELIVERY);
      expressDelivery.generalCentre = data.general_centre;
      handleSelectedDeliveryTypes({
        id: FACET_DELIVERY.ECI_EXPRESS,
        isChecked: true,
        deliveryType: DELIVERY_PARAMS.DELIVERY,
      });
      await setFilterInUrl(expressDelivery.generalCentre);
      UtilsAnalytics.sendFacetDelivery({
        label: FACET_DELIVERY.ECI_EXPRESS.replaceAll('_', ' ').toLowerCase(),
        action: 'delivery',
      });
      return;
    }
    if (!ok) {
      console.log(error);
      apiError.value = 'checkout.errors.' + error[1].data.errors[0].code;
    }
  };

  const handleCenterClickNCar = async (centerId, prevGeneralCentre, company, checked, name, food_centre) => {
    setDeliveryType(DELIVERY_TYPE.COLLECT);
    handleSelectedDeliveryTypes({
      id: FACET_DELIVERY.CLICK_AND_CAR,
      isChecked: true,
      deliveryType: DELIVERY_PARAMS.COLLECT,
    });
    const { ok } = await handleClickNCarCenterLS(centerId, company, name, food_centre);
    if (ok) {
      setDeliveryType(DELIVERY_TYPE.COLLECT);
      const NORMALIZE_URL = await removeFilter(prevGeneralCentre);
      await updateCurrentFilterUrl(NORMALIZE_URL, false);
      setCurrentStep(CURRENT_STEP.FACET_DELIVERY);
      clickAndCarCollect.generalCentre = centerId;
      clickAndCarCollect.centreTitle = name;
      clickAndCarCollect.company = company;
      handleSelectedDeliveryTypes({
        id: FACET_DELIVERY.CLICK_AND_CAR,
        isChecked: true,
        deliveryType: DELIVERY_PARAMS.COLLECT,
      });
      await setFilterInUrl(clickAndCarCollect.generalCentre);
      UtilsAnalytics.sendFacetDelivery({
        label: FACET_DELIVERY.CLICK_AND_CAR.replaceAll('_', ' ').toLowerCase(),
        action: 'collect',
      });
      return;
    }
  };

  const handleEciExpressSlots = async (timeSlot) => {
    setCurrentStep(CURRENT_STEP.FACET_DELIVERY);
    handleSelectedDeliveryTypes({
      id: FACET_DELIVERY.ECI_EXPRESS,
      isChecked: true,
      deliveryType: DELIVERY_PARAMS.DELIVERY,
    });
    const { ok } = await handleEciExpressTimeSlotLS(timeSlot);
    if (ok) {
      await setFilterInUrl(expressDelivery.generalCentre);
      UtilsAnalytics.sendFacetDelivery({
        label: FACET_DELIVERY.ECI_EXPRESS.replaceAll('_', ' ').toLowerCase(),
        action: 'delivery',
      });
      return;
    }
  };

  const handleClickNCarSlots = async (timeSlot) => {
    setCurrentStep(CURRENT_STEP.FACET_DELIVERY);
    handleSelectedDeliveryTypes({
      id: FACET_DELIVERY.CLICK_AND_CAR,
      isChecked: true,
      deliveryType: DELIVERY_PARAMS.COLLECT,
    });
    const { ok } = await handleClickNCarTimeSlotLS(timeSlot);
    if (ok) {
      await setFilterInUrl(clickAndCarCollect.generalCentre);
      UtilsAnalytics.sendFacetDelivery({
        label: FACET_DELIVERY.CLICK_AND_CAR.replaceAll('_', ' ').toLowerCase(),
        action: 'collect',
      });
      return;
    }
  };

  const removeFilter = async (generalCentre) => {
    const TYPES_FILTER = getSplitFilter();
    return await removeCurrentFilter(TYPES_FILTER, generalCentre);
  };

  const redirectTimeSlot = (time_slot) => {
    deliveryType.value === DELIVERY_TYPE.DELIVERY ? handleEciExpressSlots(time_slot) : handleClickNCarSlots(time_slot);
  };

  const handleSelectedDeliveryTypes = (selectedDeliveryType) => {
    if (!currentlySelectedDeliveryTypes.value) {
      setCurrentlySelectedDeliveryTypes([]);
    }
    const currentDeliveryTypeIndex = currentlySelectedDeliveryTypes.value.findIndex((deliveryType) => {
      return deliveryType.id == selectedDeliveryType.id;
    });
    if (currentDeliveryTypeIndex >= 0) {
      currentlySelectedDeliveryTypes.value[currentDeliveryTypeIndex].isChecked = selectedDeliveryType.isChecked;
    } else {
      currentlySelectedDeliveryTypes.value.push(selectedDeliveryType);
    }
    setCurrentlySelectedDeliveryTypes(currentlySelectedDeliveryTypes);
    updateDeliveryType();
  };

  const updateDeliveryType = () => {
    const hasCollectTypes = currentlySelectedDeliveryTypes.value.some((deliveryType) => {
      return deliveryType.deliveryType == DELIVERY_PARAMS.COLLECT && deliveryType.isChecked == true;
    });
    const hasDeliveryTypes = currentlySelectedDeliveryTypes.value.some((deliveryType) => {
      return deliveryType.deliveryType == DELIVERY_PARAMS.DELIVERY && deliveryType.isChecked == true;
    });

    if (hasDeliveryTypes && hasCollectTypes) {
      setSelectedDeliveryType(DELIVERY_PARAMS.DELIVERY_AND_COLLECT);
    } else if (hasDeliveryTypes) {
      setSelectedDeliveryType(DELIVERY_PARAMS.DELIVERY);
    } else if (hasCollectTypes) {
      setSelectedDeliveryType(DELIVERY_PARAMS.COLLECT);
    } else {
      setSelectedDeliveryType(DELIVERY_PARAMS.DELIVERY_KEY);
    }
  };

  return {
    isLoading,
    apiError,
    deliveryType,
    expressDelivery,
    clickAndCarCollect,
    removeCurrentFilter,
    updateCurrentFilterUrl,
    currentStep,
    setDeliveryType,
    setCurrentStep,
    setClickAndCar,
    setEciExpress,
    setFilterInUrl,
    setTimeSlots,
    getSplitFilter,
    existCurrentFilter,
    closeModal,
    resetState,
    redirectTimeSlot,
    handleEditTimeSlotsClickNCar,
    handlePostalCodeEciExpress,
    handleCenterClickNCar,
    handleClickNCarSlots,
    handleEciExpressSlots,
    handleSelectedDeliveryTypes,
  };
};

export default useFacetFilterLogic;
